<template>
  <v-app>

    <customize-header homeRouter="/vrPanorama/home"
      homeTitle="VR全景网站"
      :links="links"></customize-header>
    <router-view></router-view>
    <customize-suspendBtn></customize-suspendBtn>
    <customize-footer></customize-footer>
    
  </v-app>
</template>

<script>
export default {
  name: 'vrPanoramaIndex',
  data: () => ({
    links: [
      {
        title: "首页",
        router: "/vrPanorama/home",
        children: []
      },
      {
        title: "解决方案",
        router: "/vrPanorama/solution",
        children: []
      },
      {
        title: "拍摄服务",
        router: "/vrPanorama/shot",
        children: []
      },
      // {
      //   title: "案例",
      //   router: "/vrPanorama/case",
      //   children: []
      // },
      {
        title: "招商代理",
        router: "/vrPanorama/agent",
        children: []
      }
    ],
    
  }),
  
};
</script>
<style lang="scss" scoped>

</style>